import React from 'react'
import { Helmet } from "react-helmet"
import favicon from "../../assets/images/favicon.ico"
import logo from "../../assets/images/logo.png"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>ITS</title>
                <link type="image/png" rel="icon" href={favicon} />
                <link type="image/png" rel="icon" href={favicon} sizes="64x64 48x48 32x32 24x24 16x16" />
                <link type="image/png" rel="icon" href={favicon} sizes="192x192" />
                <link type="image/png" rel="icon" href={favicon} sizes="512x512" />
                <link type="image/png" rel="apple-touch-icon" href={logo} />
                <meta name="description" content="ITS - Desarrollo de software" />
                <meta name="og:title" property="og:title" content="ITS - Desarrollo de software"></meta>
                <meta name="twitter:card" content="TS - Desarrollo de software"></meta>

                {/* <link rel="canonical" href="https://rewy-react.envytheme.com/"></link>
                <meta property="og:image" content="https://res.cloudinary.com/dev-empty/image/upload/v1593069801/explore-learning.jpg" /> */}
            </Helmet>
        </div>
    )
}

export default SEO
